<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                            >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>
            <v-col cols="12">
                <v-card outined>
                    <v-container fluid>
                        <v-row class="mb-10">
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Depo </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="millModel"
                                    :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Depo"
                                      clearable>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Brand </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="brandModel" 
                                :items="brandLists" default="" item-value="brand_id" item-text="brand_id" label="Brand"
                                clearable>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Category Group </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="categoryGroupModel" 
                                :items="categoryGroupLists" default="" item-value="cat_group" item-text="cat_group" label="Category Group"
                                @change="(event) => categoryGroupOnChange(event)"
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Category </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="categoryModel" 
                                :items="categoryLists" default="" item-value="category_id" item-text="cat_desc" label="Category"
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Type </h6>
                                <v-autocomplete 
                                        dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="machModel" 
                                        :items="machLists" default="" item-value="mach_type" item-text="descr" label="Type"
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Material </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true  v-model="materialModel" 
                                :items="materialLists" default="" item-value="jenis" item-text="jenis" label="Material"
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Quality </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="gradeModel" 
                                :items="gradeLists" default="" item-value="grade" item-text="grade" label="Quality"
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Color </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="colorModel" 
                                :items="colorLists" default="" item-value="KodeWarna" item-text="NamaWarna" label="Color"
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Add Id </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true  v-model="addIDModel" 
                                :items="addIDLists" default="" item-value="add_id" item-text="descr" label="Add ID" 
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Tor Id </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="torIDModel" 
                                :items="torIDLists" default="" item-value="tor_id" item-text="descr" label="Tor ID"
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Thickness </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="thicknessModel" 
                                :items="thicknessLists" default="" item-value="Tebal" :item-text="item => item.Tebal + ' mm'" label="Thickness"
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Width </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="widthModel" 
                                :items="widthLists" default="" item-value="Lebar" :item-text="item => item.Lebar + ' mm'" label="Width"
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Length(M) </h6>
                                <v-text-field solo style="max-width: 300px;" class="ma-0 pa-1 border-12" v-model="lengthModel" onkeypress='return event.charCode >= 48 && event.charCode <= 57' label="Length(m)" dense single-line hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Product Code </h6>
                                <v-text-field solo style="max-width: 300px;" class="ma-0 pa-1 border-12" v-model="prodCodeModel" label="Product Code"  dense single-line hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Batch Id </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true   v-model="batchIDModel" :items="batchIdLists" default="" 
                                    label="Batch id" 
                                      clearable>
                                </v-autocomplete>
                            </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <v-btn class="border-12 mt-9" block style="padding: 20px;"
                                        color="info" elevation="2" small @click="getData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                    </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card>
            </v-col>
                                <div class="col-lg-12 cardResult">
                                <v-col cols="12">
                                            <v-card outlined class="p-0" >
                                                <v-card-text class="mt-0">
                                                    <v-data-table
                                                        dense
                                                        :headers="headers"
                                                        :items="itemLists"
                                                        class="elevation-1 mt-0"
                                                        :items-per-page="30"
                                                        height="300"
                                                        fixed-header
                                                        :divider="true"
                                                        :light="true"
                                                        :search="searchItem"
                                                        :item-class="itemRowBackground"
                                                        >  
                                                        <template v-slot:top>
                                                                    <v-toolbar flat color="white" class="mt-2 mb-2">
                                                                        <div class="d-flex w-100">
                                                                            <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                            <v-spacer></v-spacer>
                                                                            <v-btn
                                                                            small
                                                                            color="#fff"
                                                                            class="py-5 mr-3"
                                                                            @click="getData()"
                                                                            >
                                                                                <v-icon>mdi-cached</v-icon>
                                                                            </v-btn>
                                                                            <v-btn
                                                                            small
                                                                            color="#005c37"
                                                                            class="py-5 mr-3 text-white"
                                                                            >
                                                                            <vue-json-to-csv :json-data="itemLists" :csv-title="'Depo Stock Report'" >
                                                                                <v-btn color="#005c37" class="mr-1 text-white" small>
                                                                                    Export to CSV <i class="mdi mdi-export-variant" aria-hidden="true"></i>
                                                                                </v-btn>
                                                                            </vue-json-to-csv>
                                                                            </v-btn>
                                                                        </div>
                                                                    </v-toolbar>
                                                                </template>   
                                                </v-data-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </div>
                            </div>
                        </div>
                </template>
            <script>

import VueJsonToCsv from 'vue-json-to-csv'

export default {
    components:{
        VueJsonToCsv
    },
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'E-Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Depo Stock Report',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            brandModel: '',
            brandLists: [],
            categoryGroupModel: '',
            categoryGroupLists: [],
            categoryModel: '',
            categoryLists: [],
            machModel: '',
            machLists: [],
            materialModel: '',
            materialLists: [],
            colorModel: '',
            colorLists: [],
            addIDModel: '',
            addIDLists: [],
            torIDModel: '',
            torIDLists: [],
            gradeModel: '',
            gradeLists: [],
            thicknessModel: '',
            thicknessLists: [],
            widthModel: '',
            widthLists: [],
            lengthModel: '',
            prodCodeModel: '',
            batchIDModel: 'All',
            batchIdLists:['All', 'Y', 'N'],
            salesStat:'',
            salesStats:['A','C', 'K', 'N', 'O', 'P', 'R', 'S', 'X'],
            itemLists: [],
            headers:[
                { text: 'Brand', value: 'brand_id' , align:'left'},
                { text: 'Barang Jadi', value: 'NamaBarang' , align:'left'},
                { text: 'Material', value: 'Jenis' , align:'left'},
                { text: 'Prod Code', value: 'BarangJadiId' , align:'left'},
                { text: 'Thick. (MM)', value: 'Tebal' , align:'left',},
                { text: 'Length(M)', value: 'panjang' , align:'left'},
                { text: 'Color', value: 'NamaWarna' , align:'left', },
                { text: 'Qty', value: 'stock' , align:'left', },
                { text: 'Wgt', value: 'wgt_stock' , align:'left', },
                { text: 'Reserve', value: 'reserve' , align:'left', },
                { text: 'Wgt.Reserve', value: 'wgt_reserve' , align:'left', },
                { text: 'Unrelease', value: 'unrelease' , align:'left', },
                { text: 'Avail.', value: 'sAvailable', align: 'left',  },
                { text: 'Wgt.Avail(KG)', value: 'wgt_sAvailable' , align:'left', },
                { text: 'Add Id', value: 'add_id' , align:'left', },
                { text: 'Tor', value: 'tor_id' , align:'left', },
                { text: 'Batch Id', value: 'batch_id' , align:'center', },
                { text: 'Status', value: 'stat' , align:'center', },           
            ],
            searchItem: '',
            loadingDatatable: false,
            detailModalTitle: '',
            userGroup: null,
            appl_id: 'E-REPORTS'
        }
    },
    async mounted(){
        this.initialize()
    },
    methods:{
        itemRowBackground (item) {
            if(item.stat.trim() == "C") {
                return "tr_row_c";
            } else if(item.stat.trim() == "X") {
                return "tr_row_x";
            } else {
                return "tr_datatable";
            }
        },
        async initialize(){
            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardResult").style.display = "none";
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/DepoStockReport`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.brandLists = res.data.brand
                this.categoryGroupLists = res.data.category
                this.categoryLists = res.data.category
                this.machLists = res.data.mach
                this.materialLists = res.data.material
                this.colorLists = res.data.color
                this.addIDLists = res.data.add_id
                this.torIDLists = res.data.tor_id
                this.gradeLists = res.data.grade
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async getData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'warning',
                    })
                return false
            }

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/DepoStockReport/getData`, { 

                    mill: this.millModel ? this.millModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    category: this.categoryModel ? this.categoryModel : '',
                    mach: this.machModel ? this.machModel : '',
                    material: this.materialModel ? this.materialModel : '',
                    color: this.colorModel ? this.colorModel : '',
                    addID: this.addIDModel ? this.addIDModel : '',
                    torID: this.torIDModel ? this.torIDModel : '',
                    grade: this.gradeModel ? this.gradeModel : '',
                    thickness: this.thicknessModel ? this.thicknessModel : '',
                    width: this.widthModel ? this.widthModel : '',
                    length: this.lengthModel ? this.lengthModel : '',
                    prodCode: this.prodCodeModel ? this.prodCodeModel : '',
                    batchID: this.batchIDModel ? this.batchIDModel : ''
                    
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        categoryGroupOnChange(id){

            if(id){

                this.categoryLists = []

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/DepoStockReport/getCategoryByGroup?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.categoryLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                this.categoryLists = []

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/InvoiceAnalysis/getCategoryByGroup?id=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.categoryLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            }

        },

    }
    
}
</script>

<style>
    .tr_row_c{
        background-color: #4a71bd !important;
        color: rgb(255, 240, 240);
    }
    .tr_row_x{
        background-color: #2ba796 !important;
        color: rgb(255, 240, 240);
    }
    .tr_datatable{
        background-color: #F5F7F8 !important;
    }
    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>